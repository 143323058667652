import React from "react";
import Snippet from '../components/molecules/NodeTypes/snippet';
import Layout from "../components/templates/Layout";
import {graphql} from "gatsby";

const NodeSnippet = ({data}) => (
    <Layout
        lang={data.drupal.snippet.langcode}
        class={'snippet'}
        metatags={data.drupal.snippet.url.metatags}
        title={data.drupal.snippet.title}
        activeNid={data.drupal.snippet.nid}
    >
        <Snippet nodeContent={data.drupal.snippet} />
    </Layout>
);

export default NodeSnippet;

export const query = graphql`
query snippet($id: ID!, $language: Drupal_Langcode!) {
    drupal {
        snippet: entityById(id: $id, langcode: $language, entityType: NODE) {
            ... on Drupal_NodeSnippet {
                nid
                title
                body 
                fieldCodesnippet
                internalId: nid
                path {
                    alias
                }
                langcode
                url {
                    ... on Drupal_EntityUrl {
                        metatags {
                            tag
                            attributes {
                                key
                                value
                            }
                        }
                    }
                }                
            }
        }
    }
}
`;
