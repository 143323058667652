import React from "react";
import Link from '../link/link';
import './Button.scss';

const Button = (props) => {
    return (
        <Link to={props.link} className={props.class + ' button'} rel={props.rel}>{props.label}</Link>
    );
};

export default Button;
