import React, {Component} from "react";
import Parser from 'html-react-parser';
import Header from "../../atoms/header/header";

import Button from "../../atoms/Buttons/Button";
import {trackCustomEvent} from "gatsby-plugin-google-analytics";
import {currentLanguageByPath, getTranslations} from "../../../helpers/MainHelper";

import hljs from "highlight.js/lib/core";
import 'highlight.js/scss/default.scss';
import '../../organisms/SnippetList/SnippetList.scss';
hljs.registerLanguage('php', require('highlight.js/lib/languages/php'));

class Snippet extends Component {
    render() {
        const i18next = getTranslations(currentLanguageByPath());
        let page = this.props.nodeContent;
        let opening = '';
        let body = '';
        let title = '';

        if (this.props.title) {
            title = this.props.title;
        }
        else {
            title = page.title;
        }

        if (page.body !== undefined && page.body !== null) {
            body = page.body;
        }

        let returnLink = '/snippets';
        if (currentLanguageByPath() === 'en') {
            returnLink = '/en/snippets';
        }

        const codeSnippet = "<pre class='hljs'>" + hljs.highlight('php', page.fieldCodesnippet).value + "</pre>";

        return <>
            <Header page_title={title}>
                {opening}
            </Header>
            <div>
                {Parser(body)}
                {Parser(codeSnippet)}
            </div>

            <Button
                onClick={e => {
                    // Lets track that custom click
                    trackCustomEvent({
                        // string - required - The object that was interacted with (e.g.video)
                        category: "Back to snippet list",
                        // string - required - Type of interaction (e.g. 'play')
                        action: "click",
                    })
                    return true;
                }}
                link={returnLink}
                label={i18next.t('back_snippet')}
                class={'button-orange'}
            />
        </>
    }
}

export default Snippet;
